(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.facilities.controller:FacilitiesCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.facilities')
  .controller('FacilitiesCtrl', FacilitiesCtrl);

  function FacilitiesCtrl(facilities, townhalls, AclService) {
    var vm = this;
    vm.ctrlName = 'FacilitiesCtrl';

    vm.hasPermission = AclService.can("FACILITY", "C");

    vm.facilities = [];
    angular.forEach(facilities, function (facility) {
      facility.nameOrder = facility.name;
      facility.addressOrder = facility.address;
      facility.town = facility.townName;
      facility.townOrder = facility.townName;
      facility.townHall = findItemById(townhalls, facility.idTownHall).name;
      facility.townHallOrder = findItemById(townhalls, facility.idTownHall).name;
      vm.facilities.push(facility);
    });

    vm.data = {
      title: 'FACILITIES.LIST',
      notFoundMessage: 'RESULT_NOT_FOUND',
      headers: [
        {id: 'name', name: 'NAME', canOrder: true, orderBy: 'nameOrder'},
        {id: 'address', name: 'ADDRESS', canOrder: true, orderBy: 'addressOrder'},
        {id: 'town', name: 'TOWN', canOrder: true, orderBy: 'townOrder'},
        {id: 'townHall', name: 'TOWN_HALL', canOrder: true, orderBy: 'townHallOrder'}
      ],
      state: {
        slink: 'home.facilities.facility', params: [
          {key: 'facilityId', value: 'id'}
        ]
      },
      defaultOrderBy: 'nameOrder',
      values: vm.facilities
    };


    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
